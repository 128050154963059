import { getSupportingLayerId } from '../../helpers/CALayerID';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import LabelClass from '@arcgis/core/layers/support/LabelClass';

const CADMALayer = () => {

    const title = 'DMA';

    const labelClass = new LabelClass({
        labelExpressionInfo: {
            expression: "$feature.NAME"
        },
        symbol: new TextSymbol({
            color: [255, 0, 0, 255],
            haloSize: 1,
            haloColor: [255, 255, 255, 255],
            font: {
                size: 10,
                family: "Arial"
            }
        })
    });

    return new FeatureLayer({
        url: 'https://services.arcgis.com/AgwDJMQH12AGieWa/arcgis/rest/services/DMA/FeatureServer/0',
        title,
        id: getSupportingLayerId(title),
        visible: false,
        labelingInfo: [labelClass]
    });
};

export default CADMALayer;
