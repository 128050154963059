import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import LabelClass from '@arcgis/core/layers/support/LabelClass';

export const CATextSymbol = () => {
    return new TextSymbol({
        haloSize: 3,
        haloColor: [255, 255, 255],
        yoffset: -4,
        color: [0, 0, 0],
        font: {
            size: 9,
            weight: 'bold'
        }
    });
};

export const CALayerLabels = () => {
    const labelClass = new LabelClass({
        symbol: CATextSymbol(),
        labelPlacement: 'above-center',
        minScale: 1000000,
        labelExpressionInfo: {
            expression: '$feature.NAME'
        }
    });

    return labelClass;
};

export const CXYLayerLabels = () => {
    const labelClass = new LabelClass({
        symbol: CATextSymbol(),
        labelPlacement: 'above-center',
        minScale: 1000000,
        labelExpressionInfo: {
            expression: "When(Find($feature.Label, 'Walmart USA') > -1, Concatenate($feature.Label, ' ', $feature.StoreType, ' ', $feature.StoreNumber), Concatenate($feature.Label, ' ', $feature.StoreNumber))"
        }
    });

    return labelClass;
};
