import { CXYLayerLabels } from '../../helpers/CALayerLabels';
import CXYPopup from '../../popups/CXYPopup';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import UniqueValueInfo from '@arcgis/core/renderers/support/UniqueValueInfo';
import { SimpleMarkerSymbol, Symbol } from '@arcgis/core/symbols';
import StatisticDefinition from '@arcgis/core/rest/support/StatisticDefinition';

export const chainGroceryTitle = 'Chain Grocery';

const defaultSymbol = new SimpleMarkerSymbol({
    style: "circle",
    color: [50, 205, 50, 255],
    size: 8,
    outline: {
        color: [0, 0, 0, 255],
        width: 1
    }
});

const CXYChainGroceryLayer = async () => {
    const url = 'https://services8.arcgis.com/osXWqrTKrwWBat15/arcgis/rest/services/Collection_296590/FeatureServer/0'

    // Create a temporary layer to fetch the default renderer
    const tempLayer = new FeatureLayer({
        url: url
    });

    // Wait for the layer to load to get its renderer
    await tempLayer.load();
    
    // Get the default renderer and ensure it's a UniqueValueRenderer before cloning
    const renderer = tempLayer.renderer as UniqueValueRenderer;
    const defaultRenderer = renderer.clone();

    // Query chain names with special handling for Walmart
    const query = tempLayer.createQuery();
    query.returnDistinctValues = true;
    query.returnGeometry = false;
    query.outFields = ["ChainName", "StoreType"];
    query.outStatistics = [
        new StatisticDefinition({
            statisticType: "count",
            onStatisticField: "OBJECTID",
            outStatisticFieldName: "count"
        })
    ];
    query.groupByFieldsForStatistics = [
        "ChainName",
        "StoreType"
    ];
    query.orderByFields = ["ChainName", "StoreType"];

    const results = await tempLayer.queryFeatures(query);
    
    // Create a map of existing unique value infos for quick lookup
    const existingInfos = new Map(
        defaultRenderer.uniqueValueInfos.map(info => [info.value, info])
    );

    if (defaultRenderer.type === "unique-value") {
        // Process non-Walmart chains first
        const regularChains = results.features
            .filter(f => f.attributes.ChainName !== 'Walmart USA')
            .reduce((acc: UniqueValueInfo[], feature) => {
                const chainName = feature.attributes.ChainName;
                // Skip if we already have this chain name
                if (acc.some(info => info.value === chainName)) {
                    return acc;
                }
                
                const existingInfo = existingInfos.get(chainName);
                acc.push(new UniqueValueInfo({
                    value: chainName,
                    label: `${chainName}`,
                    symbol: existingInfo ? (existingInfo.symbol as Symbol).clone() : (defaultSymbol as Symbol).clone()
                }));
                return acc;
            }, []);

        // Process Walmart entries
        const walmartEntries = results.features
            .filter(f => f.attributes.ChainName === 'Walmart USA')
            .map(feature => {
                const chainName = feature.attributes.ChainName;
                const storeType = feature.attributes.StoreType;
                const value = `${chainName}|${storeType}`;
                const existingInfo = existingInfos.get(chainName);

                return new UniqueValueInfo({
                    value: value,
                    label: `${chainName} - ${storeType}`,
                    symbol: existingInfo ? (existingInfo.symbol as Symbol).clone() : (defaultSymbol as Symbol).clone()
                });
            });

        defaultRenderer.uniqueValueInfos = [...walmartEntries, ...regularChains];
        defaultRenderer.field = "ChainName";
        defaultRenderer.valueExpression = `
            IIF($feature.ChainName == 'Walmart USA',
                $feature.ChainName + '|' + $feature.StoreType,
                $feature.ChainName)
        `;
    }

    const layer = new FeatureLayer({
        url: url,
        title: `${chainGroceryTitle}`,
        visible: true,
        definitionExpression: '1<>1',
        labelsVisible: false,
        legendEnabled: true,
        labelingInfo: [CXYLayerLabels()],
        popupTemplate: CXYPopup(),
        renderer: defaultRenderer
    });

    return layer;
};

export default CXYChainGroceryLayer;
